import { injectGlobal } from '@emotion/css';

injectGlobal`
.TanningTool {
  background-color: #e5eff5;
  padding: 16px 0;
  text-align: center;
  overflow: hidden;
}

.TanningTool-slider {
  position: relative;
  width: calc(100% - 16px * 2);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.TanningTool-sliderBar, .TanningTool-sliderBarActive {
  width: 100%;
  height: 4px;
  background-color: white;
  border-radius: 4px;
  position: absolute;
  left: 0;
  top: calc(50% - 2px);
  overflow: hidden;
}

.TanningTool-sliderBarActive {
  width: 100%;
  background-color: #B6A79A;
  transform: translateX(-83%);
  transition: .5s;
}

.TanningTool-sliderDots {
  position: relative;
  display: flex;
  justify-content: space-around;
}

.TanningTool-sliderDot {
  width: 24px;
  height: 24px;
  border-radius: 24px;
  background-color: white;
  color: black;
  font-size: 14px;
  line-height: 24px;
  transition-delay: 0.45s;
}

.TanningTool-sliderDot.active {
  background-color: #B6A79A;
  color: white;
}

.TanningTool-main {
  width: 400%;
  min-height: 450px;
  padding: 16px 0;
  display: flex;
  transition: 0.5s ease-in;
}

.TanningTool-section {
  width: 100%;
}

label, input{
  cursor: pointer;
}

.select-box {
display: flex;
width: calc(100% - 16px * 2);
flex-direction: column;
position: relative;
margin: 0 16px;
text-align: left;
}

.select-box .options-container {
max-height: 0;
width: 100%;
opacity: 0;
transition: all 0.4s;
overflow: hidden;
border-radius: 5px;
border: solid 1px #dcdfe5;
background-color: #ffffff;
order: 1;
position: absolute;
top: 68px;
}

.selected {
background: #2f3640;
margin-bottom: 8px;
position: relative;
width: 100%;
height: 64px;
border-radius: 5px;
border: solid 1px #dcdfe5;
background-color: #ffffff;
order: 0;
overflow: hidden;
}

.selected svg {
position: absolute;
right: 15px;
top: 50%;
transition: transform .5s;
transform: translateY(-50%);
}


.selected .option-title, .select-box label .option-title {
font-size: 18px;
font-weight: 700;
line-height: 1.1;
color: #1f2949;
display: block;
margin: 0;
}
.selected .option-text, .select-box label .option-text {
font-size: 14px;
font-weight: 400;
line-height: 1.83;
color: #81878f;
margin: 0;
} 

.select-box .options-container.active {
max-height: 240px;
opacity: 1;
overflow-y: scroll;
}

.selected .option-text {
width: 100%;
text-overflow: ellipsis;
overflow: hidden;
white-space: nowrap;
}

.select-box .options-container.active + .selected svg {
transform: translateY(-50%) rotateX(180deg);
}

.select-box .options-container::-webkit-scrollbar {
width: 8px;
background: #0d141f;
background: #81878f;
background: #f1f2f3;
border-radius: 0 5px 5px 0;
}

.select-box .options-container::-webkit-scrollbar-thumb {
background: #525861;
background: #81878f;
border-radius: 0 5px 5px 0;
}
.select-box .option,
.selected {
padding: 12px 24px;
cursor: pointer;
}

.select-box .option:hover {
background: #eaf0f3;
}

.select-box label {
cursor: pointer;
}

.select-box .option .radio {
display: none;
}

.TanningTool.show-result .TanningTool-next {
display: none;
}
.TanningTool.show-result .TanningTool-restart {
display: initial;
}

.TanningTool-next {
background-color: #B6A79A;
color: white;
width: calc(100% - 16px * 2);
margin: 0 16px;
padding: 16px;
}

.TanningTool-restart {
background-color: transparent;
border: none;
text-decoration: underline;
padding: 16px 32px;
display: none;
}

.styled-checkbox {
 position: absolute;
 opacity: 0;
}
.styled-checkbox + label {
 position: relative;
 cursor: pointer;
 padding: 0;
 font-size: 18px;
}
.styled-checkbox + label:before {
 content: '';
 margin-right: 10px;
 display: inline-block;
 vertical-align: text-top;
 width: 20px;
 height: 20px;
 background: white;
}
.styled-checkbox:hover + label:before {
 border: 2px solid #B6A79A;
}
.styled-checkbox:focus + label:before {
 box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
}
.styled-checkbox:checked + label:before {
 background: #B6A79A;
}
.styled-checkbox:checked + label:after {
 content: '';
 position: absolute;
 left: 5px;
 top: 9px;
 background: white;
 width: 2px;
 height: 2px;
 box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
 transform: rotate(45deg);
}

.TanningTool-checkboxList {
margin: 0;
padding: 0;
list-style-type: none;
text-align: left;

margin: -0.5em 0 0 0 !important;
min-width: auto !important;
}

.TanningTool-skin {
display: flex;
flex-wrap: wrap;
}

.TanningTool-skin:valid label:before {
opacity: 0.4;
}

.TanningTool-skinRadio {
position: absolute;
left: 0;
opacity: 0;
visibility: hidden;
}

.TanningTool-skinBlock {
flex: 1 0 50%;
}

.TanningTool-skinBlock:nth-of-type(1) label::before { background-color: #f8ebdb; }
.TanningTool-skinBlock:nth-of-type(2) label::before { background-color: #F3C6A5; }
.TanningTool-skinBlock:nth-of-type(3) label::before { background-color: #EDBA8A; }
.TanningTool-skinBlock:nth-of-type(4) label::before { background-color: #C19670; }
.TanningTool-skinBlock:nth-of-type(5) label::before { background-color: #CC8162; }
.TanningTool-skinBlock:nth-of-type(6) label::before { background-color: #B06F51; }
.TanningTool-skinBlock:nth-of-type(7) label::before { background-color: #754734; }
.TanningTool-skinBlock:nth-of-type(8) label::before { background-color: #472B1F; }

.TanningTool-skinRadio + label {
position: relative;
margin: 8px;
cursor: pointer;
line-height: 20px;
display: flex;
flex-direction: column;
align-items: center;
text-align: center;
}

.TanningTool-skinRadio + label:before {
content: '';
position: relative;
width: 60px;
height: 60px;
border: 2px solid black;
border-radius: 100%;
margin-bottom: 8px;
transition: .3s;
}

.TanningTool-skinBlock:hover label:before {
opacity: 1;
}
.TanningTool-skinRadio:checked + label:before {
border-color: white;
box-shadow: 0 3px 20px -2px rgba(0, 0, 0, .6);
opacity: 1 !important;
}

.TanningTool-skinRadio:checked + label {
font-weight: bold;
}

.TanningTool-result * {
font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

@media (min-width: 650px) {
.select-box {
  width: 400px;
  margin: 0 auto;
}

.TanningTool-checkboxList {
  flex: 1 0 50%;
  display: inline-block;
}

.TanningTool-checkboxList + .TanningTool-checkboxList {
  margin: -0.5em 0 0 16px !important;
}

.TanningTool-skin {
  width: 80%;
  margin: 40px auto 0;
}

.TanningTool-skinBlock {
  flex: 1 0 25%;
  margin-top: 16px;
}
`;
